<template>
  <div class="card" :class="{ error: error }">
    <div class="section-top">
      <div class="day">
        <span class="body-text-bold">Day</span>
        <input
          class="days grid-text-large"
          type="number"
          placeholder="7"
          :value="reward.days"
          @focusout="onDaysInput"
          @input="checkDaysInput"
        />
      </div>
      <div v-if="reward.emoji" class="emoji" @click="onSelectEmoji">
        {{ reward.emoji }}
      </div>
      <div v-else class="emoji placeholder" @click="onSelectEmoji">🎁</div>
    </div>
    <div>
      <input
        class="title h3"
        placeholder="New Reward"
        type="text"
        :value="reward.title"
        @focusout="onTitleInput"
        maxlength="25"
      />
      <div class="description" @click="onMoreClick">
        <span>{{ reward.description || "Add more" }}</span>
        <img :src="require('@/assets/more.svg')" alt="add more" />
      </div>
    </div>
    <div class="remove-button" @click="onRemove">
      <img :src="require('@/assets/trash.svg')" alt="remove" />
    </div>
    <emoji-picker
      v-if="showEmojiPicker"
      @emoji-click="onEmojiClick"
    ></emoji-picker>
    <div class="backdrop" v-if="showEmojiPicker" @click="onBackdropClick"></div>
  </div>
</template>

<script setup>
import { Reward } from "@/models/reward";
import "emoji-picker-element";
import { defineEmits, defineProps, ref } from "vue";

let showEmojiPicker = ref(false);

const props = defineProps({
  reward: Reward,
});

const error = ref(false);

const validate = () => {
  const daysValid = props.reward.days && props.reward.days > 0;
  const titleValid = props.reward.title && props.reward.title.length > 0;
  if (daysValid && titleValid) {
    return true;
  }

  error.value = true;
  setTimeout(() => {
    error.value = false;
  }, 500);

  return false;
};

// eslint-disable-next-line no-undef
defineExpose({ validate });

const emit = defineEmits(["remove", "update", "more"]);

const onRemove = () => {
  emit("remove", props.reward.id);
};

const onTitleInput = (event) => {
  emit("update", { ...props.reward, title: event.target.value });
};

const onDaysInput = (event) => {
  emit("update", { ...props.reward, days: +event.target.value });
};

const onSelectEmoji = () => {
  showEmojiPicker.value = true;
};

const onEmojiClick = (event) => {
  emit("update", { ...props.reward, emoji: event.detail.unicode });
  showEmojiPicker.value = false;
};

const onBackdropClick = () => {
  showEmojiPicker.value = false;
};

const checkDaysInput = (event) => {
  let value = event.target.value;

  if (value !== 0 && !value) {
    return;
  }

  if (value < 1) {
    value = 1;
  } else if (value > 500) {
    value = +value.toString().slice(0, value.toString().length - 1);
  }
  event.target.value = +value;
};

const onMoreClick = () => {
  emit("more", props.reward.id);
};
</script>

<style scoped>
.card {
  /* Figma */
  width: 211px;
  height: 204px;
  flex-shrink: 0;

  border-radius: 28px;
  background: var(--Background---Light);

  /* adaptions */
  padding: 26px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.card.error {
  animation: shake 0.4s;
}

.section-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day {
  display: flex;
  flex-direction: column;
}

.emoji {
  /* Figma */
  font-family: Nunito;
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 46.643px */

  cursor: pointer;
}

.placeholder {
  color: var(--Placeholder);
}

.title {
  background: transparent;
  text-overflow: ellipsis;

  /* adaptions */
  border: none;
  outline: none;
  width: 100%;
}

input::placeholder {
  color: var(--Placeholder);
}

input {
  padding: 0;
}

.description {
  display: flex;
  gap: 5px;
  max-width: 120px;

  font-family: Nunito;
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--Foreground---Secondary);

  cursor: pointer;
}

.description span {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.days {
  background: transparent;

  /* adaptions */
  border: none;
  outline: none;
  width: 90px;
}

/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* To hide the clear button in MS Edge/IE */
input::-ms-clear {
  display: none;
}

.remove-button {
  position: absolute;
  top: -13px;
  right: -13px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

emoji-picker {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

/* mobile */
@media only screen and (max-width: 450px) {
  .card {
    width: 100%;
    padding: 26px 39px;
  }
}
</style>
