<template>
  <div class="app-wrapper">
    <div class="title-section">
      <div class="title-container">
        <span class="title-huge" id="title-first">Gift a</span>
        <span class="title-huge" id="title-second">Habit</span>
      </div>
      <div class="habit-row fast">
        <div class="habit-items">
          <HabitSample v-for="item in habitEmojis1" :emoji="item" :key="item" />
          <HabitSample v-for="item in habitEmojis1" :emoji="item" :key="item" />
        </div>
      </div>
      <div class="habit-row slow">
        <div class="habit-items">
          <HabitSample v-for="item in habitEmojis2" :emoji="item" :key="item" />
          <HabitSample v-for="item in habitEmojis2" :emoji="item" :key="item" />
        </div>
      </div>
    </div>
    <div class="content-section">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import HabitSample from "./HabitSample.vue";

const habitEmojis1 = ["📚", "🏃", "🧘", "🎨", "🎸"];
const habitEmojis2 = ["✍️", "🌱", "🏋️‍♀️", "🍎", "🙏"];
</script>

<style scoped>
.app-wrapper {
  display: flex;
  min-height: 100vh;
  width: 100vw;
}

.content-section {
  flex: 1 1 auto;
  background-color: var(--Background);
  z-index: 1;
  padding: 150px 100px 100px 150px;
  box-sizing: border-box;
}

.title-section {
  width: 420px;
  background-color: var(--Brand);
  display: flex;
  flex-direction: column;
  position: relative;
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-810px);
  }
}

.habit-row {
  animation: moveRightToLeft 10s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.habit-row.slow {
  animation-duration: 20s;
}

.habit-row.fast {
  animation-duration: 15s;
}

.habit-items {
  display: flex;
  width: 200%; /* Adjust based on your items */
}

.offset-row {
  position: relative;
  left: -81px;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
}

#title-first {
  color: #fff;
}

#title-second {
  color: rgba(255, 255, 255, 0.6);
}

/* smaller devices */
@media screen and (max-width: 1200px) and (min-width: 900px) {
  .content-section {
    padding: 150px 50px 100px 50px;
  }

  .title-section {
    width: 350px;
  }
}

@media screen and (max-width: 900px) and (min-width: 680px) {
  .content-section {
    padding: 150px 20px 100px 20px;
  }

  .title-section {
    width: 250px;
  }

  .title-huge {
    font-size: 70px;
    max-width: 210px;
    text-align: center;
  }

  .habit-row {
    display: none;
  }
}

/* Mobile */
@media only screen and (max-width: 680px) {
  .app-wrapper {
    flex-direction: column;
  }

  .title-section {
    display: none;
  }

  .content-section {
    padding: 0 20px 50px 20px;
    flex-direction: column;
  }
}
</style>
