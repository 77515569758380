<template>
  <div class="chip" @click="onClick">
    <img v-if="iconSrc" :src="iconSrc" :alt="label" />
    <span class="caption">{{ label }}</span>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

defineProps({
  label: String,
  iconSrc: String,
});

const emit = defineEmits(["click"]);

const onClick = () => {
  emit("click");
};
</script>

<style scoped>
.chip {
  /* Figma */
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 15px;
  background: var(--Brand);
  box-shadow: 1.55px 2.326px 1.783px 0px rgba(255, 255, 255, 0.4) inset,
    2.326px 2.326px 6.279px 0px rgba(0, 0, 0, 0.1);

  /* adaptions */
  cursor: pointer;
  user-select: none;
}

.caption {
  /* overwrites */
  color: var(--Background);
}
</style>
