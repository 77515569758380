<template>
  <div class="container">
    <div class="caption">
      {{ label }}<span class="optional" v-if="!required"> (optional)</span>
    </div>
    <div
      v-if="multiline"
      class="textarea-container"
      @click="onTextAreaContainerClick"
    >
      <textarea
        class="input-text"
        ref="textarea"
        :placeholder="placeholder"
        v-model="model"
        :required="required"
        :type="type"
        :maxlength="maxLength"
        @input="
          $refs.textarea.style.height = 'auto';
          $refs.textarea.style.height = $refs.textarea.scrollHeight + 'px';
        "
      ></textarea>
    </div>
    <div v-else class="input-container" :class="{ error: error }">
      <input
        class="input-text"
        :placeholder="placeholder"
        v-model="model"
        :required="required"
        :type="type"
        :maxlength="maxLength"
        @input="checkNumberInput"
        @focus="hasFocus = true"
      />
      <span
        v-if="emoji"
        class="emoji"
        :class="{ pulse: hasFocus }"
        @click="onSelectEmoji"
        >{{ emoji }}</span
      >
    </div>
    <emoji-picker
      v-if="showEmojiPicker"
      @emoji-click="onEmojiClick"
    ></emoji-picker>
    <div class="backdrop" v-if="showEmojiPicker" @click="onBackdropClick"></div>
  </div>
</template>

<script setup>
import "emoji-picker-element";
import { defineEmits, defineModel, defineProps, ref } from "vue";

const model = defineModel({ type: String });
const props = defineProps({
  label: String,
  placeholder: String,
  required: Boolean,
  emoji: String,
  type: {
    type: String,
    default: "text",
  },
  multiline: {
    type: Boolean,
    default: false,
  },
  maxLength: Number,
  min: Number,
  max: Number,
});

const error = ref(false);
const hasFocus = ref(false);

const validate = () => {
  if (model.value || !props.required) {
    return true;
  }

  error.value = true;
  setTimeout(() => {
    error.value = false;
  }, 500);

  return false;
};

// eslint-disable-next-line no-undef
defineExpose({ validate });

const emit = defineEmits(["updateEmoji"]);

const showEmojiPicker = ref(false);

const onSelectEmoji = () => {
  showEmojiPicker.value = true;
};

const onBackdropClick = () => {
  showEmojiPicker.value = false;
};

const onEmojiClick = (event) => {
  emit("updateEmoji", event.detail.unicode);
  showEmojiPicker.value = false;
};

const onTextAreaContainerClick = () => {
  const textarea = document.querySelector("textarea");
  if (textarea) {
    textarea.focus();
  }
};

const checkNumberInput = (event) => {
  if (!props.min && !props.max) {
    return;
  }

  let value = event.target.value;

  if (value !== 0 && !value) {
    return;
  }

  if (value < props.min) {
    value = props.min;
  } else if (value > props.max) {
    value = +value.toString().slice(0, value.toString().length - 1);
  }

  model.value = +value;
};
</script>

<style scoped>
.container {
  /* Figma */
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  gap: 9.412px;
  position: relative;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6.275px;
  background: var(--Background---Light);
  height: 53px;
  box-sizing: border-box;
  padding: 15px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.input-container.error {
  animation: shake 0.4s; /* Adjust duration as needed */
}

.textarea-container {
  background: var(--Background---Light);
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 6.275px;
}

.input-text {
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  width: 100%;
}

textarea {
  min-height: 200px;
  max-height: 300px;
  resize: none;
}

.input-text::placeholder {
  color: var(--Placeholder);
}

/* For Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* To hide the clear button in MS Edge/IE */
input::-ms-clear {
  display: none;
}

.placeholder {
  color: var(--Placeholder);
}

.optional {
  color: var(--Foreground---Secondary);
}

.emoji {
  /* Figma */
  font-family: Nunito;
  font-size: 28px;
  font-style: normal;
  cursor: pointer;
}

.pulse {
  animation: pulse 3s 5;
  animation-timing-function: ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

emoji-picker {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
</style>
