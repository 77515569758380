<template>
  <div class="card">
    <span class="h3"> Share your gift </span>
    <img
      class="share-card"
      src="@/assets/share-card.png"
      alt="A graphically appealing card that can be downloaded and printed. It serves as the gift to the person you configured the habit for."
    />
    <div class="link">
      <span>{{ link }}</span>
      <ChipButton :label="copyLabel" :icon-src="copyIcon" @click="onCopy" />
    </div>
    <div class="actions">
      <MainButton
        label="Share Link"
        :icon-src="require('@/assets/export.svg')"
        @click="onShare"
        v-if="showShareButton"
      />
      <MainButton
        label="Download card"
        :icon-src="require('@/assets/download.svg')"
        @click="onDownload"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import ChipButton from "./ChipButton.vue";
import MainButton from "./MainButton.vue";

const props = defineProps({
  link: String,
});

const copyLabel = ref("Copy");
const copyIcon = ref(require("@/assets/copy.svg"));
const showShareButton = !!navigator.share;

const onCopy = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(props.link);
  } else {
    // Fallback for http and browsers that do not support clipboard API
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = props.link;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
  }

  copyLabel.value = "Copied!";
  copyIcon.value = null;
  setTimeout(() => {
    copyLabel.value = "Copy";
    copyIcon.value = require("@/assets/copy.svg");
  }, 5000);
};

const onShare = () => {
  navigator
    .share({
      title: "Gift a Habit",
      text: "Open the link to see the gift!",
      url: props.link,
    })
    .catch();
};

const onDownload = () => {
  const link = document.createElement("a");
  link.href = require("@/assets/share-card-download.jpg");
  link.download = "gift-a-habit.jpg";
  link.click();
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  padding: 30px 20px 30px 20px;
  border-radius: 38px;
  gap: 10px;

  background: var(--Background);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.share-card {
  width: 100%;
}

.link {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 10px 10px 20px;
  border-radius: 20px;
  background: var(--Background---Light);

  max-width: 70%;
  box-sizing: border-box;
  margin-top: 10px;
}

.link span {
  color: var(--Foreground---Secondary);

  /* Web/Body Bold */
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actions {
  display: flex;
  gap: 10px;
  margin-top: 50px;
}

/* Mobile */
@media screen and (max-width: 680px) {
  .link {
    max-width: 100%;
  }

  .link span {
    font-size: 16px;
  }
}
</style>
