<template>
  <div class="card" @click="onClick">
    <img src="@/assets/plus-dark.svg" alt="add" />
    <span class="h3 placeholder">Add new</span>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";
const emit = defineEmits(["click"]);

const onClick = () => {
  emit("click");
};
</script>

<style scoped>
.card {
  /* Figma */
  width: 211px;
  height: 204px;

  border-radius: 28px;
  background: var(--Background---Light);

  /* adaptions */
  padding: 26px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  cursor: pointer;
}

.placeholder {
  color: var(--Placeholder);
}

.card img {
  width: 30%;
  height: 30%;
}

/* mobile */
@media only screen and (max-width: 450px) {
  .card {
    width: 100%;
  }
}
</style>
