<template>
  <AppWrapper>
    <div class="habit-container">
      <div class="content">
        <div class="header">
          <span class="grid-text-large">Gift a Habit</span>
        </div>
        <div class="configuration">
          <InputField
            label="Type of habit"
            placeholder="Workout"
            :required="true"
            :emoji="habitEmoji"
            @updateEmoji="updateEmoji"
            v-model="habitType"
            ref="typeInput"
            max-length="30"
          />
          <div class="interval">
            <span class="caption">Interval - for streaks</span>
            <DropDown
              :options="options"
              :selected="iteration"
              @select="selectIteration"
              @click="showIntervalHint = true"
            />
            <span v-if="showIntervalHint" class="caption hint"
              >Currently only daily interval is supported</span
            >
          </div>
        </div>
        <div class="reward-container">
          <div class="reward-header">
            <span class="caption">Rewards</span>
            <ChipButton
              label="Add new"
              :icon-src="require('@/assets/plus.svg')"
              @click="openRewardDetails"
              v-if="rewards.length > 0 && rewards.length < 5"
            />
          </div>
          <div class="reward-items">
            <RewardCardPlaceholder
              v-if="!rewards.length"
              @click="openRewardDetails"
            ></RewardCardPlaceholder>
            <RewardCard
              v-for="reward in rewards"
              :key="reward.id"
              :reward="reward"
              ref="rewardCards"
              @remove="removeReward"
              @update="updateReward"
              @more="openRewardDetails"
            ></RewardCard>
          </div>
        </div>
      </div>
      <div class="action">
        <MainButton
          label="Gift habit"
          class="share-button"
          @click="submit"
          :icon-src="require('@/assets/export.svg')"
        />
        <span class="caption light">⚠️ Currently only supported on iOS</span>
      </div>
      <div
        class="backdrop blur"
        v-if="showRewardDetails || currentReward || showShareCard"
        @click="closeDialog()"
      ></div>
      <RewardDetails
        class="dialog"
        v-if="showRewardDetails"
        @close="closeDialog()"
        @save="addReward"
      ></RewardDetails>
      <RewardDetails
        class="dialog"
        v-if="currentReward"
        :reward="currentReward"
        @close="closeDialog()"
        @save="updateReward"
      ></RewardDetails>
      <ShareCard class="dialog" v-if="showShareCard" :link="shareLink" />
    </div>
  </AppWrapper>
</template>

<script setup>
import AppWrapper from "@/components/AppWrapper.vue";
import ChipButton from "@/components/ChipButton.vue";
import DropDown from "@/components/DropDown.vue";
import InputField from "@/components/InputField.vue";
import MainButton from "@/components/MainButton.vue";
import RewardCard from "@/components/RewardCard.vue";
import RewardCardPlaceholder from "@/components/RewardCardPlaceholder.vue";
import RewardDetails from "@/components/RewardDetails.vue";
import ShareCard from "@/components/ShareCard.vue";
import { Base64 } from "js-base64";
import { inject, onMounted, ref } from "vue";

const rewards = ref([]);
const options = [
  { id: 1, title: "Daily" },
  // currently only daily is supported
  // { id: 2, title: "Weekdays" },
  // { id: 3, title: "Bi-Daily" },
  // { id: 4, title: "Weekly" },
];
const showRewardDetails = ref(false);
const showShareCard = ref(false);
const showIntervalHint = ref(false);

const iteration = ref(1);
const habitType = ref("");
const habitEmoji = ref("💪");

const typeInput = ref(null);
const rewardCards = ref([]);
const currentReward = ref(null);
const shareLink = ref("");

const td = inject("td");

onMounted(() => {
  // Preload image
  const img = new Image();
  img.src = require("@/assets/share-card.png");
});

const openRewardDetails = (id) => {
  if (id) {
    const reward = rewards.value.find((r) => r.id === id);
    currentReward.value = { ...reward };
  } else {
    showRewardDetails.value = true;
  }
};

const addReward = (reward) => {
  closeDialog();
  rewards.value.push(reward);
  rewards.value.sort((a, b) => a.days - b.days);
};

const removeReward = (id) => {
  rewards.value = rewards.value.filter((reward) => reward.id !== id);
};

const updateReward = (reward) => {
  currentReward.value = null; // close in case of dialog was used

  const index = rewards.value.findIndex((r) => r.id === reward.id);
  rewards.value[index] = reward;

  rewards.value.sort((a, b) => a.days - b.days);
};

const selectIteration = (id) => {
  iteration.value = id;
};

const submit = async () => {
  let isValid = true;
  if (!typeInput.value.validate()) {
    isValid = false;
  }

  for (const card of rewardCards.value) {
    if (!card.validate()) {
      isValid = false;
    }
  }

  if (!isValid) {
    return;
  }

  const habit = {
    type: habitType.value,
    emoji: habitEmoji.value,
    iteration: iteration.value,
    rewards: rewards.value,
  };

  const habitString = JSON.stringify(habit);
  const encodedHabit = Base64.encode(habitString, true);

  const baseUrl = window.location.origin;

  shareLink.value = `${baseUrl}/gift?habit=${encodedHabit}`;
  showShareCard.value = true;

  td.signal("Habit.Share", {
    type: habitType.value,
    rewards: rewards.value.map((r) => ({
      days: r.days,
      title: r.title,
    })),
  });
};

const updateEmoji = (emoji) => {
  td.signal("Habit.UpdateEmoji", { emoji });
  habitEmoji.value = emoji;
};

const closeDialog = () => {
  showRewardDetails.value = false;
  currentReward.value = null;
  showShareCard.value = false;
};
</script>

<style scoped>
.habit-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  position: relative;
  gap: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: flex-start;
}

.configuration {
  width: 370px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.reward-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.reward-header {
  display: flex;
  gap: 15px;
  align-items: center;
  height: 32px;
}

.reward-items {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.interval {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 9.412px;
}

@keyframes fadeInUp {
  0% {
    transform: translate(-50%, -40%);
    opacity: 0;
  }
  40% {
    transform: translate(-50%, -40%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  animation: fadeInUp 0.6s ease-out;
  backface-visibility: hidden;
}

.header {
  display: none;
}

.hint {
  position: absolute;
  bottom: -30px;
  color: var(--Foreground---Secondary);
  animation: fadeInOut 10s;
  opacity: 0;
}

.action {
  display: flex;
  gap: 30px;
  align-items: center;
}

.light {
  color: var(--Foreground---Secondary);
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Mobile */
@media only screen and (max-width: 680px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0 20px;
    height: 15vh;
    min-height: 80px;
    width: 100%;
    background-color: var(--Brand);
    align-self: center;
  }

  .grid-text-large {
    color: #fff;
    margin-bottom: 20px;
  }

  .habit-container {
    align-items: center;
  }

  .content {
    gap: 30px;
    width: 100%;
  }

  .configuration {
    width: 100%;
  }

  .reward-container {
    gap: 20px;
  }

  .share-button {
    width: 100%;
  }

  .dialog {
    width: 95%;
    max-height: 90%;
    box-sizing: border-box;
  }

  .action {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .reward-container {
    width: 100%;
  }

  .reward-items {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
