<template>
  <div class="card">
    <div class="header">
      <span class="h3">{{
        reward?.title ? "Edit Reward" : "Add new Reward"
      }}</span>
      <div class="close" @click="onClose">
        <img src="@/assets/close.svg" alt="close" />
      </div>
    </div>
    <div class="main-inputs">
      <InputField
        v-model="localReward.days"
        v-focus
        class="day"
        label="Day"
        placeholder="7"
        type="number"
        :required="true"
        :min="1"
        :max="500"
      ></InputField>
      <InputField
        v-model="localReward.title"
        label="Title"
        placeholder="Protein bar"
        :emoji="localReward.emoji"
        @update-emoji="localReward.emoji = $event"
        :required="true"
        :max-length="25"
      ></InputField>
    </div>
    <InputField
      v-model="localReward.description"
      class="secondary-input"
      label="Description"
      placeholder="You can find it below your bed"
      type="text"
      :multiline="true"
      :max-length="1000"
    ></InputField>
    <MainButton class="save" label="Save" @click="onSave()" />
    <div class="bottom-area">
      <div class="shortcut">
        <img src="@/assets/control.svg" alt="control key" />
      </div>
      <div class="shortcut">
        <img src="@/assets/enter.svg" alt="enter key" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Reward } from "@/models/reward";
import { defineEmits, defineProps, onMounted, onUnmounted, ref } from "vue";
import InputField from "./InputField.vue";
import MainButton from "./MainButton.vue";

const vFocus = {
  mounted: (el) => {
    const inputElement = el.querySelector("input");
    if (inputElement) {
      inputElement.focus();
    }
  },
};

const props = defineProps({
  reward: Reward,
});

const localReward = ref({});

onMounted(() => {
  localReward.value = { ...(props.reward || new Reward("", "", "🍫", "")) };
  document.addEventListener("keydown", onKeyDown);
});

onUnmounted(() => {
  document.removeEventListener("keydown", onKeyDown);
});

const emit = defineEmits(["close", "save"]);

const onClose = () => {
  emit("close");
};

const onSave = () => {
  emit("save", localReward.value);
};

const onKeyDown = (event) => {
  // Check if the 'Ctrl' and 'Enter' keys were pressed at the same time
  if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
    // If they were, call the onSave method
    onSave();
  }

  // Check if the 'Escape' key was pressed
  if (event.key === "Escape") {
    // If it was, call the onClose method
    onClose();
  }
};
</script>

<style scoped>
.card {
  display: inline-flex;
  padding: 30px 30px 56px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 31px;

  border-radius: 12px;
  background: var(--Background);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}

.close {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.main-inputs {
  display: flex;
  width: 370px;
  gap: 20px;
}

.secondary-input {
  width: 100%;
  min-height: 200px;
}

.day {
  width: 90px;
}

.save {
  width: 100%;
}

.bottom-area {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 36px;
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 5px;
  background-color: var(--Background---Light);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 10px;
  box-sizing: border-box;
}

.shortcut {
  display: flex;
  width: 21px;
  height: 21px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: var(--Foreground---Secondary);
}

/* Mobile */
@media only screen and (max-width: 680px) {
  .main-inputs {
    width: 100%;
  }

  .card {
    padding: 20px;
  }

  .bottom-area {
    display: none;
  }
}
</style>
