<template>
  <select
    class="dropdown input-text"
    :value="selected"
    @change="onSelect"
    @click="onClick"
  >
    <option v-for="option in options" :value="option.id" :key="option.id">
      {{ option.title }}
    </option>
  </select>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
defineProps({
  options: Array,
  selected: Number,
});

const emit = defineEmits(["select", "click"]);

const onSelect = (event) => {
  emit("select", +event.target.value);
};

const onClick = () => {
  console.log("click");
  emit("click");
};
</script>

<style scoped>
.dropdown {
  padding: 15px;
  border-radius: 6.275px;
  background: var(--Background---Light);

  border: none;
  outline: none;

  /* Hide the default arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Add custom arrow */
  background-image: url("@/assets/arrow.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15.7px);
}
</style>
