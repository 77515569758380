<template>
  <AppWrapper>
    <div class="gift-container">
      <div class="header">
        <span class="title-huge">Congrats 🎉</span>
        <div class="sub-title">
          <span class="h3 light"
            >You've been gifted one of the greatest gifts,</span
          >
          <span class="h3"> a new habit</span>
          <span v-if="habit?.emoji" class="h3">{{ " " + habit.emoji }}</span>
        </div>
      </div>
      <div class="hint">
        <div class="hint-top">
          <QrcodeVue :value="currentUrl" :size="200" :margin="0" />
          <div class="hint-card">
            <span class="iphone">📱</span>
            <span class="h3"> Open on your iPhone </span>
          </div>
        </div>
        <span class="hint-text">
          To redeem your gift, open the link on your phone and follow the
          instructions.
        </span>
      </div>
      <div class="instructions">
        <div class="step">
          <span class="h1">Step 1</span>
          <span class="body-text"
            >Copy your personalized link to automatically paste it into the app
            after it is downloaded and opened.</span
          >
          <MainButtonVue
            :label="copyLabel"
            class="mobile-button"
            :icon-src="copyIcon"
            @click="copyLink"
          />
        </div>
        <div class="step">
          <span class="h1">Step 2</span>
          <MainButtonVue
            label="Download the app"
            class="mobile-button"
            :icon-src="require('@/assets/download.svg')"
            @click="downloadApp"
          />
        </div>
        <div class="step">
          <span class="h1">Step 3</span>
          <span class="body-text">
            Open the app, your link in your clipboard will automatically be
            detected and redeemed 🎉
          </span>
        </div>
      </div>
    </div>
  </AppWrapper>
</template>

<script setup>
import AppWrapper from "@/components/AppWrapper.vue";
import MainButtonVue from "@/components/MainButton.vue";
import { Base64 } from "js-base64";
import QrcodeVue from "qrcode.vue";
import { computed, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const habit = ref(null);
const isAndroid = ref(false);
const copyLabel = ref("Copy link");
const copyIcon = ref(require("@/assets/copy.svg"));

const currentUrl = computed(() => window.location.href);

const td = inject("td");

// TODO: add user agent check and show instructions only for iphone

onMounted(() => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  isAndroid.value = /android/i.test(userAgent);
});

try {
  const route = useRoute();
  const habitBase64 = route.query.habit;
  const habitJson = Base64.decode(habitBase64);
  habit.value = JSON.parse(habitJson);
} catch (error) {
  console.error(error);
}

if (habit.value) {
  td.signal("Gift.Receive", {
    type: habit.value.type,
    rewards: habit.value.rewards.map((r) => ({
      days: r.days,
      title: r.title,
    })),
  });
}

const copyLink = () => {
  td.signal("Gift.CopyLink");
  const link = window.location.href;
  if (navigator.clipboard) {
    navigator.clipboard.writeText(link);
  } else {
    // Fallback for http and browsers that do not support clipboard API
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = link;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);
  }

  copyLabel.value = "Copied!";
  copyIcon.value = null;
  setTimeout(() => {
    copyLabel.value = "Copy link";
    copyIcon.value = require("@/assets/copy.svg");
  }, 5000);
};

const downloadApp = () => {
  td.signal("Gift.DownloadApp");
  window.open("https://apps.apple.com/de/app/id6451364783");
};
</script>

<style scoped>
.gift-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 80%;
  gap: 40px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.light {
  color: var(--Foreground---Secondary);
}

.hint {
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: 600px;
}

.hint-top {
  display: flex;
  gap: 50px;
  align-items: center;
}

.hint-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 120px;
  border-radius: 30px;
  padding: 25px 50px 25px 25px;
  box-sizing: border-box;
  gap: 13px;

  background: var(--Background---Light);
}

.iphone {
  font-size: 68px;
}

.hint-text {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;

  color: var(--Foreground---Secondary);
}

.instructions {
  display: none;
}

/* Mobile */
@media only screen and (max-width: 680px) {
  .gift-container {
    justify-content: start;
    gap: 50px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 0 20px;
    height: 25vh;
    min-height: 150px;
    width: 100%;
    background-color: var(--Brand);
    align-self: center;
    gap: 7px;
  }

  .title-huge {
    color: #fff;
    font-size: 30px;
    line-height: 30px;
  }

  .sub-title {
    width: 80%;
    margin-bottom: 30px;
  }

  .h3 {
    color: #fff;
  }

  .light {
    color: rgba(255, 255, 255, 0.6);
  }

  .hint {
    display: none;
  }

  .instructions {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 90%;
  }

  .step {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mobile-button {
    width: fit-content;
  }
}
</style>
