<template>
  <router-view />
</template>

<script setup>
import TelemetryDeck from "@telemetrydeck/sdk";
import { provide } from "vue";

const randomGuid = () => {
  // Check if a GUID is already stored in localStorage
  let guid = localStorage.getItem("vid");

  // If not, generate a new GUID and store it in localStorage
  if (!guid) {
    guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );

    localStorage.setItem("vid", guid);
  }

  return guid;
};

const td = new TelemetryDeck({
  appID: "7FDF1D9D-2C2B-4802-9FF4-A528DA35840D",
  clientUser: randomGuid(),
  testMode: process.env.VUE_APP_TEST_MODE == "true",
});

td.signal("Init");

provide("td", td);
</script>

<style>
#app {
  font-family: "Nunito", sans-serif;
}

body {
  margin: 0;
  overflow-x: hidden;
}
</style>
